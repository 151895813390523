import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';

export default function IframeView() {
  const iframeRef = useRef(null);
  const [iframeUrl, setIframeUrl] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [clientName, setClientName] = useState('');

  useEffect(() => {
    // Função para buscar a URL do iframe
    const fetchIframeUrl = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/iframe-url`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        const { iframeUrl, clientName } = response.data;


        // Configurar a URL do iframe usando a referência
        if (iframeRef.current) {
          iframeRef.current.src = iframeUrl;
        }

        document.title = response.data.clientName || 'Relatório BI';
        
        // Atualizar o estado
        setIframeUrl(iframeUrl);
        setClientName(clientName);
      } catch (error) {
        console.error('Erro ao buscar URL do iframe:', error);
      }
    };

    // Função para detectar a abertura do DevTools
    const detectDevTools = () => {
      const threshold = 200;
      const isDevToolsOpen = window.outerWidth - window.innerWidth > threshold || window.outerHeight - window.innerHeight > threshold;

      if (isDevToolsOpen) {
        document.body.innerHTML = ''; // Remove o conteúdo da página
      }
    };

    // Adicionar o listener de resize para detectar o DevTools
    window.addEventListener('resize', detectDevTools);

    // Buscar a URL do iframe quando o componente for montado
    fetchIframeUrl();
    setStartTime(Date.now()); // Registrar o tempo de início quando o componente é montado

    // Função para enviar o tempo de tela para o backend
    const sendTimeToServer = async (duration) => {
      try {
        const token = localStorage.getItem('token');
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/record-time`, { duration }, {
          headers: { Authorization: `Bearer ${token}` }
        });
      } catch (error) {
        console.error('Erro ao registrar o tempo de tela:', error);
      }
    };

    // Calcular e enviar o tempo de tela quando o componente é desmontado
    return () => {
      window.removeEventListener('resize', detectDevTools);
      if (startTime) {
        const duration = Math.round((Date.now() - startTime) / 1000); // Calcular a duração em segundos
        sendTimeToServer(duration); // Enviar o tempo de tela para o servidor
      }
    };
  }, [startTime]);

  return (
    <div
      className="min-h-screen bg-gray-100 flex flex-col items-center justify-center"
      onContextMenu={(e) => e.preventDefault()} // Desabilitar menu de contexto
    >
      <div className="p-1 rounded-lg shadow-md w-full">
      <div className="aspect-w-16 aspect-h-8">
          <iframe
            ref={iframeRef} // Referência para o elemento iframe
            title="Relatório BI"
            className="w-full h-full"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}