import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const ProtectedRoute = ({ children, adminRequired = false }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const verifyAuth = async () => {
      const token = localStorage.getItem('token'); // Usar 'token' para usuários normais
      const adminToken = localStorage.getItem('adminToken'); // Manter 'adminToken' separado

      if (!token && !adminToken) {
        setIsLoading(false);
        return;
      }

      try {
        // Se a rota requer admin, verificar apenas com adminToken
        if (adminRequired) {
          if (!adminToken) {
            setIsAuthorized(false);
            setIsLoading(false);
            return;
          }

          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/verify-admin-auth`,
            {},
            {
              headers: { 'Authorization': `Bearer ${adminToken}` }
            }
          );
          setIsAuthorized(response.data.isAdmin);
        } else {
          // Para rotas normais, aceitar qualquer token válido
          const activeToken = adminToken || token;
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/verify-auth`,
            {},
            {
              headers: { 'Authorization': `Bearer ${activeToken}` }
            }
          );
          setIsAuthorized(true);
        }
      } catch (error) {
        console.error('Error verifying authentication:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('adminToken');
      } finally {
        setIsLoading(false);
      }
    };

    verifyAuth();
  }, [adminRequired]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthorized) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
