import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function AuthCallback() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    if (token) {
      localStorage.setItem('adminToken', token);
      navigate('/admin');
    } else {
      navigate('/');
    }
  }, [navigate, location]);

  return <div>Autenticando...</div>;
}

export default AuthCallback;