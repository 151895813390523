import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import IframeView from './components/IframeView';
import ProtectedRoute from './components/ProtectedRoute';
import AuthCallback from './components/AuthCallback'; // Nova importação aqui

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/auth-callback" element={<AuthCallback />} />

        {/* Rotas protegidas */}
        <Route path="/iframe" element={<ProtectedRoute><IframeView /></ProtectedRoute>} />
        {/* Rota de fallback */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;